<template>
    <el-card style="height: 100%">
          <swiper navigation :options="{autoplay:true}">
            <swiper-slide v-for="(brand,index) in siteList" :key="index">
              <div>
                <el-image
                  style="max-width: 100%; height: 300px"
                  :src="brand"
                  fit="cover"
                ></el-image>
              </div>
            </swiper-slide>
          </swiper>
        </el-card>
</template>

<script>
import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.baiwanqilin.com/qilin-tool/v1",
});
export default {
  name: "Slide",
  data() {
    return {
      siteList: []
    };
  },
  async created() {
    const {
      data: { data },
    } = await instance.get("/site/getSite");
    this.siteList = data.brand.split(',');
  }
};
</script>
