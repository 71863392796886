<template>
  <div class="topTitle">
    <h2>{{ title }}</h2>
    <div class="juxing"></div>
  </div>
</template>

<script>
export default {
  name: "topTitle",
  props: ["title"],
};
</script>

<style scoped lang="scss">
.topTitle {
  position: relative;
  text-align: center;
  h2 {
    font-size: 50px;
    font-weight: bold;
  }
  .juxing {
    width: 128px;
    height: 16px;
    margin: -12px auto 0;
    background: linear-gradient(90deg, #ffb600 0%, rgba(255, 182, 0, 0.4) 100%);
    border-radius: 10px;
  }
}
</style>
