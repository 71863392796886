const equipment = () => {
  let systems = 'MzEwMDAw';
  if (/Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) systems = 'app';
  return systems;
};

const localStorages = (k, v) => {
  if (v) {
    localStorage.setItem(k, JSON.stringify(v));
  } else {
    const parse = localStorage.getItem(k);
    if (parse) return JSON.parse(parse);
    return '';
  }
};
const handleImage = (u, cb) => {
  const video = document.createElement('video');
  video.setAttribute('crossOrigin', 'anonymous');
  video.setAttribute('src', u);
  video.currentTime = 1;
  video.addEventListener('loadeddata', () => {
    let canvas = document.createElement('canvas');
    const width = video.videoWidth;
    const height = video.videoHeight;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(video, 0, 0, width, height);
    const dataURL = canvas.toDataURL('image/jpeg');
    cb && cb(dataURL);
  });
};
const secondToTimeStr = t => {
  if (!t) return;
  let a,
    i,
    e = parseInt(t / 3600);
  if (t < 60) return '00:' + ((i = t) < 10 ? '0' + i : i);
  if (t < 3600) return '' + ((a = parseInt(t / 60)) < 10 ? '0' + a : a) + ':' + ((i = t % 60) < 10 ? '0' + i : i);
  if (3600 <= t) {
    return (e < 10 ? '0' + e : e) + ':' + ((a = parseInt((t % 3600) / 60)) < 10 ? '0' + a : a) + ':' + ((i = t % 60) < 10 ? '0' + i : i);
  }
};
import { MessageBox } from 'element-ui';
const confirm = (message, title, buttonText = '确定') => {
  return new Promise(resolve => {
    MessageBox.confirm(message, title, {
      confirmButtonText: buttonText,
      cancelButtonText: '取消',
      showClose: false
    })
      .then(() => resolve())
      .catch(() => {});
  });
};
const videoState = index => {
  //视频状态:0审核中,1审核通过 ,2未通过:标题原因,3你还未成为创作者，请使用APP前往认证,4视频未通过:视频原因，99草稿
  let str = '';
  switch (index) {
    case 0:
      str = '审核中';
      break;
    case 1:
      str = '审核通过';
      break
    case 2:
      //str = '未通过:标题原因';
      str = '未通过';
      break;
    case 3:
      str = '你还未成为创作者，请使用APP前往认证';
      break;
    case 4:
      //str = '视频未通过:视频原因';
      str = '未通过';
      break;
    // case 99:
    //   str = '草稿';
    //   break;
  }
  return str;
};
const videoStates = {
  0: '视频正在审核中',
  2: '视频未通过--视频存在违规内容，不予通过',
  3: '您还不是创作者，请前往APP进行创作者认证',
  4: '视频未通过--视频存在违规内容，不予通过'
};
/**
 * 清除登录信息
 */
const clearLoginInfo = () =>{
  localStorage.clear();
}
export { equipment, localStorages, handleImage, confirm, videoState, secondToTimeStr, videoStates,clearLoginInfo };
