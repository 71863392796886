import axios from 'axios';
//axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api';
axios.defaults.baseURL = "https://api.baiwanqilin.com/";
import { Message, Loading } from 'element-ui';
import router from '../router';
import store from '../store';
let loadingInstance;
axios.interceptors.request.use(config => {
  const { token } = store.getters.userData;
  const url = config.url;
  if (!url.includes('aliyuncs') && token) config.headers['token'] = token;
  if (!url.includes('saveFullVideoByWeb') && !url.includes('saveEditVideoByWeb')) startLoading();
  return config;
});
const startLoading = () => {
  const options = {
    lock: true,
    text: '加载中...',
    background: 'rgba(0,0,0,0.3)'
  };
  loadingInstance = Loading.service(options);
};

const endLoading = () => {
  loadingInstance.close();
};
axios.interceptors.response.use(
  response => {
    endLoading();
    if (response.data && response.data.stateCode == 409) { // 409, token失效
      localStorage.clear();
      store.commit('isLogin', true);
      router.push('/');
    }
    return response;
  },
  error => {
    setTimeout(() => {
      endLoading();
    }, 1000);
    if (error && error.response) {
      let msg;
      switch (error.response.status) {
        case 400:
          msg = '错误请求';
          break;
        case 401:
          msg = 'token验证失效,请重新登录';
          break;
        case 403:
          msg = '拒绝访问';
          break;
        case 404:
          msg = '请求错误,未找到该资源';
          break;
        case 405:
          msg = '请求方法未允许';
          break;
        case 408:
          msg = '请求超时';
          break;
        case 409:
          msg = 'token失效,请重新登录';
          router.push({ name: 'login' })
          return;
        //break;
        case 500:
          msg = '服务器端出错';
          break;
        case 501:
          msg = '网络未实现';
          break;
        case 502:
          msg = '网络错误';
          break;
        case 503:
          msg = '服务不可用';
          break;
        case 504:
          msg = '网络超时';
          break;
        case 505:
          msg = 'http版本不支持该请求';
          break;
        default:
          msg = `连接错误 ${error.response.status}`;
      }
      Message.error(msg);
    } else {
      Message.error('连接到服务器失败');
    }
  }
);
const headerJson = {
  'content-type': 'application/json;charset=UTF-8'
};
const headerForm = {
  'content-type': 'application/x-www-form-urlencoded'
};
const headerMultipart = {
  'content-type': 'multipart/form-data'
};
const axiosAll = async (url, data = '', multipart = '', method = 'POST') => {
  try {
    const res = await axios({
      url: url,
      method,
      headers: multipart ? headerMultipart : method == 'POST' ? headerJson : headerForm,
      data: data
    });
    if (res && res.data) return res.data;
  } catch (error) {
    console.log(error);
  }
};
export { axiosAll };
