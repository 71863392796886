<template>
  <div>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in items"
        :label="item.label"
        :key="index"
      >
        <el-form :inline="true">
          <el-form-item label="日期">
            <el-date-picker v-model="time" type="month" placeholder="选择月">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="type" placeholder="请选择">
              <el-option
                v-for="item in typelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loadData()">查询</el-button>
          </el-form-item>
        </el-form>

        <div v-if="list.length > 0" style="color: #000;">
          <div
            v-for="(item, index) in list"
            :key="index"
            style="display: flex;align-content: center;border-bottom: 1px solid #ccc;padding-bottom: 10px;"
          >
            <div class="content" style="padding-left: 10px;flex: 1;">
              <div>
                <span style="font-size: 20px;">{{ item.title }}</span>
              </div>
              <div>
                <span class="text-cut">{{ item.createTime }}</span>
              </div>
            </div>
            <div style="font-size: 20px;">
              {{ item.money }}
            </div>
          </div>
          <div style="padding-top: 20px; margin-left: -10px">
            <el-pagination
              @current-change="currentChange"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { qilinUser } from "@/http/request";
import dayjs from "dayjs";
export default {
  name: "Course",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 120,
      currentPath: 0,
      userData: null,
      list: [],
      type: 0,
      typelist: [
        {
          value: 0,
          label: "全部类型",
        },
        {
          value: 1,
          label: "全部收入",
        },
        {
          value: 2,
          label: "全部支出",
        },
        {
          value: 3,
          label: "提现",
        },
        {
          value: 4,
          label: "麒麟币充值",
        },
        {
          value: 5,
          label: "退款",
        },
        {
          value: 6,
          label: "全部礼物",
        },
        {
          value: 7,
          label: "创作礼物",
        },
        {
          value: 8,
          label: "庄园礼物",
        },
      ],
      time: dayjs().startOf("month").format(),
      items: [{ label: "购买记录", path: "/myWallet/billingDetails" }],
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.currentPath = this.items[0].path;
    await this.loadData();
  },
  methods: {
    currentChange() {
      this.loadData();
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.currentPath = this.items[parseInt(tab.index)].path;
      this.loadData();
    },
    async loadData() {
      const { pageNum, pageSize, time, type } = this;
      const {
        data: { list },
      } = await qilinUser.get(this.currentPath, {
        params: {
          type: type,
          pageNum: pageNum,
          time: time,
          pageSize: pageSize,
        },
      });
      if (list.length <  this.pageSize) {
        this.total = this.pageNum * this.pageSize;
      }
      this.list = list;
    },
  },
};
</script>
