<template>
  <div>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane v-for="item in items" :label="item.label" :key="item.label">
        <div v-if="videoList.length > 0">
          <el-row :gutter="10">
            <el-col
              :span="4"
              v-for="video in videoList"
              :key="video.videoId"
              style="position: relative"
            >
              <el-card :body-style="{ padding: '0px',textAlign:'center' }">
                <a :href="'/#/player?id='+video.videoId" @click="toPlayer(video)" target="_blank">
                  <el-image
                    style="width: 200px; height: 200px"
                    :src="video.videoImg"
                    :alt="video.videoTitle"
                    fit="cover"
                  ></el-image>
                  <el-tag
                    style="
                      position: absolute;
                      right: 10px;
                      top: 10px;
                      color: #000;
                    "
                    type="info"
                    >{{ getDuration(video.videoDuration) }}</el-tag
                  >
                  <div style="padding: 0 14px 0 14px; color: #000">
                    <div style="height: 50px; overflow: hidden">
                      {{ video.videoTitle }}
                    </div>
                  </div>
                </a>
              </el-card>
            </el-col>
          </el-row>
          <div style="padding-top: 20px; margin-left: -10px">
            <el-pagination
              @current-change="currentChange"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import axios from "@/http/https";
import qs from "qs";

export default {
  name: "UserVideo",
  props: {
    otherId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 12,
      total: 120,
      userId: null,
      currentPath: 0,
      userData: null,
      videoList: [],

      items: [
        { label: "作品", path: "/dynamicState" },
      ],
    };
  },
  async created() {
    this.currentPath = this.items[0].path;
    this.loadData();
  },
  methods: {
    currentChange() {
      this.loadData();
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.currentPath = this.items[parseInt(tab.index)].path;
      this.loadData();
    },
    async loadData() {
      const { pageNum, pageSize, otherId } = this;
      var parm = qs.stringify( { pageNum, pageSize, userId:otherId });

      const { data:{arr} } = await axios.post(this.currentPath, parm);
      if (arr.length < this.pageSize) {
        this.total = this.pageNum * this.pageSize;
      }
      this.videoList = arr.map(item=>{
        return item.video
      });
    },
    getDuration(videoDuration) {
      var mini = videoDuration % 60;
      if (mini < 10) {
        mini = "0" + mini;
      }
      return parseInt(videoDuration / 60) + ":" + mini;
    },
    toPlayer(obj) {
      localStorage.setItem("rowData", JSON.stringify(obj));
    },
  },
};
</script>
