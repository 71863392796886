//import { axiosAll } from '@/http/https.js';
import { axiosAll } from './http.js';
//alert(333)
const login = data => axiosAll(`qilin-user/v1/login`, data); //登录接口1
const getfollowvideo = data => axiosAll(`qilin-media/v1/cecommend/recommendAttention`, data); //获取推荐视频接口

const getCode = () => axiosAll(`qilin-user/v1/getCode`); //微信第一步获取code
const getWxUser = openId => axiosAll(`qilin-user/v1/getWxUser?openId=${openId}`); //根据openid获取微信用户信息
const getQqUser = openId => axiosAll(`qilin-user/v1/getQqUser?openId=${openId}`); //根据openid获取QQ用户信息,并登录
const qqLogin = () => axiosAll(`qilin-user/v1/qqLogin`, '', '', 'GET'); //根据openid获取QQ用户信息,并登录

const sendSms = phone => axiosAll(`/qilin-user/v1/sendSms?phone=${phone}`, '', '', 'GET'); //获取验证码接口

const refreshToken  =data =>axiosAll(`qilin-user/v1/refreshToken?identitytype=${data.identitytype}&phoneSystemType=${data.phoneSystemType}&userId=${data.userId}`);//刷新token接口

const getEditingVideoVoucher = (videoId = '') => axiosAll(`qilin-media/v1/getEditingVideoVoucher?videoId=${videoId}`); //WEB端获取高光视频或小技能视频的上传凭证
const getFullVideoVoucher = (videoId = '') => axiosAll(`qilin-media/v1/getFullVideoVoucher?videoId=${videoId}`); //WEB端获取完整视频的上传凭证
const videoSave = data => axiosAll(`qilin-media/v1/videoSave`, data); //完整视频上传接口2
const skillVideoSave = data => axiosAll(`qilin-media/v1/skillVideoSave`, data); //web端保存其它信息接口

const getVideoConfig = data => axiosAll(`qilin-user/v1/getVideoConfig`, '', '', 'GET'); //获取 视频时长配置接口

//完整版视频这接口第一步
const saveFullVideoByWeb = data => axiosAll(`qilin-media/v1/saveFullVideoByWeb`, data, 'multipart'); // WEB上传完整视频
//要是小视频这个第一步，完整版视频第2步
const saveEditVideoByWeb = data => axiosAll(`qilin-media/v1/saveEditVideoByWeb`, data, 'multipart'); //WEB上传高光视频或小技能视频
const saveCover = data => axiosAll(`qilin-media/v1/saveCover`, data, 'multipart'); //WEB端上传视频封面
const saveCoverByWeb = data => axiosAll(`qilin-media/v1/saveCoverByWeb?videoId=${data.videoId}&type=${data.type}`); //WEB端上传视频封面 类型:0表示是完整视频的封面,1高光视频或小技能视频的封面)
const saveVideoByWeb = data => axiosAll(`qilin-media/v1/saveVideoByWeb`, data); //WEB端保存视频其他信息

const commitVideoCompile = data => axiosAll(`qilin-tool/v1/al/commitVideoCompile?inTime=${data.inTime}&outTime=${data.outTime}&mediaId=${data.mediaId}`); //提交视频合成按钮

const videoAllDataByWeb = data => axiosAll(`qilin-media/v1/videoAllDataByWeb?pageNum=${data.pageNum}&pageSize=${data.pageSize}&typeOne=${data.typeOne}&typeTwo=${data.typeTwo}`); //视频管理列表主页
const originatorSearchVideoByPage = data => axiosAll(`qilin-media/v1/originatorSearchVideoByPage?key=${data.key}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`, '', '', 'GET'); //搜索视频你想要的视频  新接口18
const videoStick = data => axiosAll(`qilin-media/v1/videoStick?videoId=${data.videoId}&type=${data.type}`); //视频管理 - 视频置顶11

const selectLists = videoId => axiosAll(`qilin-media/v1/knowledgeBase/selectLists?videoId=${videoId}`); //管理提问列表6

const knowledgeBaseInsert = data => axiosAll(`qilin-media/v1/knowledgeBase/insert`, data, 'multipart'); //管理提问列表7

const getOneById = kId => axiosAll(`/qilin-media/v1/knowledgeBase/getOneById?kId=${kId}`); //管理提问-获取修改数据接口
const knowledgeBaseUpdate = data => axiosAll(`qilin-media/v1/knowledgeBase/update`, data, 'multipart'); //修改提问接口9
const knowledgeBaseDelete = kId => axiosAll(`qilin-media/v1/knowledgeBase/delete?kId=${kId}`); //删除提问接口8

const compilations = userId => axiosAll(`qilin-media/v1/videoCompilations/compilations?otherId=${userId}`); //合集管理-查看合集列表接口13  查看合集列表接口-选择合集下拉框
const compilationsByPage = data => axiosAll(`qilin-media/v1/videoCompilations/compilationsByPage?otherId=${data.otherId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`); //合集管理列表接口-分页

const selectVideosBy = data => axiosAll(`qilin-media/v1/videoCompilations/selectVideosBy?compilationsId=${data.compilationsId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&type=${data.type}`); //合集管理-查看合集-分页

const insertCompilation = data => axiosAll(`qilin-media/v1/videoCompilations/insertCompilation`,data); //合集管理-添加合集接口4
const deleteCompilation = compilationsId => axiosAll(`qilin-media/v1/videoCompilations/deleteCompilation?compilationsId=${compilationsId}`); //合集管理-删除合集接口10

const updateCompilation = data => axiosAll(`qilin-media/v1/videoCompilations/updateCompilation`,data); //合集管理-修改合集接口 无编号

const videoSelectOne = videoId => axiosAll(`qilin-media/v1/videoSelectOne?videoId=${videoId}`); //查看单个视频14
const videoUpdate = data => axiosAll(`qilin-media/v1/videoUpdate?videoId=${data.videoId}&videoCompilationsId=${data.videoCompilationsId}&videoTitle=${data.videoTitle}&videoDescribe=${data.videoDescribe}&isExclusive=${data.isExclusive}&prices=${data.prices}&videoLabelOne=${data.videoLabelOne}&videoLabelTwo=${data.videoLabelTwo}&isUpdate=""`); //修改视频的接口15
const getSystem = data => axiosAll(`qilin-user/v1/user/getSystem?userId=${data.userId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`, '', '', 'GET'); //消息

const getThreeNum = data => axiosAll(`qilin-user/v1/user/getThreeNum`, '', '', 'GET'); //21.获取关注数,粉丝数,好友数 接口

const authorize = data => axiosAll(`https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id`, '', '', 'GET');
const getOauthLoginInfo = data => axiosAll(`/oauth2/${data}/info`, '', '', 'GET');

const graphqq = data => axiosAll(`https://graph.qq.com/user/get_user_info?access_token=YOUR_ACCESS_TOKEN&oauth_consumer_key=YOUR_APP_ID&openid=YOUR_OPENID`, '', '', 'GET');

const category = data => axiosAll(`qilin-media/v1/category/list`, '', '', 'GET'); //获取视频分类--下拉框
export {
  login,
  getfollowvideo,
  getCode,
  getWxUser,
  getQqUser,
  qqLogin,
  sendSms,
  refreshToken,
  videoSave,
  skillVideoSave,
  compilations,
  videoAllDataByWeb,
  originatorSearchVideoByPage,
  videoStick,
  selectLists,
  knowledgeBaseInsert,
  knowledgeBaseUpdate,
  knowledgeBaseDelete,
  insertCompilation,
  deleteCompilation,
  updateCompilation,
  compilationsByPage,
  selectVideosBy,
  getSystem,
  getThreeNum,
  authorize,
  graphqq,
  saveFullVideoByWeb,
  saveVideoByWeb,
  saveEditVideoByWeb,
  getOneById,
  getVideoConfig,
  videoSelectOne,
  videoUpdate,
  getOauthLoginInfo,
  commitVideoCompile,
  saveCover,
  getEditingVideoVoucher,
  getFullVideoVoucher,
  saveCoverByWeb,
  category
};
