<template>
    <div id="app">
        <router-view v-if="isRouterAlive"/>
    </div>
</template>

<script>
export default {
    name: 'App',
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
    }
}
}
</script>
//引用组件自带样式
<style src="vue-notifyjs/themes/default.css"></style>

<style lang="less">
* {
    padding: 0;
    margin: 0;
}
ul,li {
    padding:0;
    margin:0;
    list-style:none
}
a {
    text-decoration: none;
    color: #fff;
}
body {
    font-family: "PingFang SC", "Lantinghei SC", "Microsoft YaHei","Helvetica Neue", "Open Sans", Arial, "Hiragino Sans GB", sans-serif;
    background-color: #161722;
}
button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
}
input {
    outline:none;
    border: 0;
    background:none;
}
// 隐藏滚动条
::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}
</style>