<template>
  <div style="width: 1200px; margin: 0 auto">
    <div
      v-if="personalCenter"
      :style="
        'display: flex;align-items: center;height:300px;background-size:cover;background-image:url(' +
        personalCenter.userBackgroundWall +
        ')'
      "
    >
      <div style="display: flex; line-height: 30px">
        <div style="padding: 20px">
          <img
            style="width: 100px; height: 100px; border-radius: 50px"
            :src="personalCenter.userImg"
            alt=""
          />
        </div>
        <div>
          <div>
            {{ personalCenter.userName }}
            <span v-if="personalCenter.sex == '0'">男</span
            ><span v-else>女</span>
          </div>
          <div style="display: flex">
            <div style="margin-right: 20px">
              {{ personalCenter.attentionNum }} 关注
            </div>
            <div>{{ personalCenter.fansNum }} 粉丝</div>
          </div>
          <div>
            ID：{{ personalCenter.userIdentificationId }}
            {{ personalCenter.age }}岁
          </div>

          <div>{{ personalCenter.userDescribed }}</div>
        </div>
      </div>
    </div>
    <UserVideo :otherId="otherId" />
  </div>
</template>
<script>
import { qilinUser } from "@/http/request";
import UserVideo from "./UserVideo.vue";
var qs = require("qs");

export default {
  name: "UserInfo",
  components: { UserVideo },
  data() {
    return {
      userData: null,
      personalCenter: null,
      otherId: null,
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.otherId = this.$route.query.otherId;
    var parm = qs.stringify({
      otherId: this.otherId,
    });
    const { data } = await qilinUser.post("/user/personalCenter", parm);
    this.personalCenter = data;
  },
  methods: {},
};
</script>
