<template>
  <div>
    <div class="tab1">
      <div class="tltle">用户协议</div>
      <div class="tab1_text">
        <span>感谢您申请百万麒麟账户！在成为百万麒麟用户之前，请您仔细阅读以下条文，确保您理解并同意本协议全部内容。百万麒麟平台是由郑州百万麒麟科技有限公司（百万麒麟）运营的及供需关系、视频播放、社交为一体的综合平台，域名为baiwanqilin.com。以下内容将构成您和郑州百万麒麟科技有限公司之间的服务合同条款。</span>
        <h4>
          <strong>
            <span>一、用户注册</span>
          </strong>
        </h4>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您同意本协议并注册成功即成为百万麒麟的注册用户直至您的账户注销。百万麒麟可能在必要的时候对本协议条款及百万麒麟各单项服务协议进行更改，此种更改在百万麒麟上公布或在具体服务过程中经双方以口头、书面等形式协商一致生效。您可以选择停止使用百万麒麟相关的服务或者注销您在百万麒麟的账户，否则百万麒麟将认为您同意更改后的服务条款。未在百万麒麟发布或在具体服务过程中未经百万麒麟告知的服务条款将不具有法律效力，除非签有书面协议，并且仅对签署协议的当事人有效。</span>

        <h4>
          <strong>
            <span>二、用户账户</span>
          </strong>
        </h4>

        <span>您应当拥有中华人民共和国法律认可的完全民事权利能力和完全民事行为能力，否则您和能够代表您行使权利或承担责任的其他主体将承担一切后果。为此，您应当提供相应的证明。如果您是自然人，此类证明可以是您的居民身份证件、个人户营业执照。如果您是法人，此类证明可以是您的营业执照。如果您是其他组织类型，您应当提供相应的合法证明。</span>

        <span>如果使用百万麒麟提供的各单项服务，您可能还需要提交其他相关的资料和信息。为了提供更好的服务，您也可以向我们提供其他资料和信息，我们将按照百万麒麟的隐私政策保护您的资料和信息。</span>

        <span>您应当保护好您的账户，除非已经得到您的提前通知，百万麒麟将认为您的账户处于您的控制之下。如果您的账户在不受您控制的情况下处于风险状态或者已经发生损失，请您及时以有效方式通知百万麒麟，您可以要求百万麒麟暂停服务或者冻结账户。</span>

        <span>如果您使用百万麒麟账户直接访问百万麒麟项目关联的其他网站，您应当遵守该网站的服务条款。</span>

        <h4>
          <strong>
            <span>三、账户管理</span>
          </strong>
        </h4>
        <span>1、百万麒麟帐号的所有权归百万麒麟所有，用户完成申请注册手续后，获得百万麒麟帐号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。百万麒麟因经营需要，有权回收用户的百万麒麟帐号。</span>
        <span>2、用户可以更改、删除百万麒麟帐户上的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。</span>
        <span>3、用户有责任妥善保管注册帐号信息及帐号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的帐号或密码。在用户怀疑他人使用其帐号或密码时，用户同意立即通知百万麒麟。</span>
        <span>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，百万麒麟在通知用户后有权依据协议中断或终止对违约用户百万麒麟帐号提供服务。同时，百万麒麟保留在任何时候收回百万麒麟帐号、用户名的权利。</span>
        <span>5、如用户注册百万麒麟帐号后一年不登录，通知用户后，百万麒麟可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担。</span>
        <h4>
          <strong>
            <span>四、用户责任</span>
          </strong>
        </h4>
        <span>用户使用百万麒麟必须遵守所有适用的国家法律、地方法规和国际准则。用户对用户账户进行的一切操作及言论负完全的责任，用户注销账户后，仍然应当对注销前的操作和言论负责。</span>
        <p>
          <span>用户必须遵循：</span>
        </p>
        <span>（1）从中国境内向外传输技术性资料时必须符合中国有关法规。</span>
        <span>（2）使用网络服务不作非法用途</span>
        <span>（3）不干扰或混乱网络服务</span>
        <span>（4）遵守所有使用网络服务的网络协议、规定、程序和惯例。</span>
        <p>
          <span>用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽等信息资料。用户也不能传输任何教唆他人构成犯罪行为的资料。不得进行任何有可能对百万麒麟的系统造成任何不良的影响的操作，不能传输助长国内不利条件和涉及国家安全的资料。不能传输任何不符合当地法规、国家法律和国际准则的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上提到的服务条款，百万麒麟单方面有权冻结或注销用户账户。用户需对用户本身在网上的行为承担法律责任。用户若在百万麒麟上散布和传播反动、色情或其他违反国家法律的信息，百万麒麟的系统记录有可能作为用户违反法律的证据。</span>
        </p>
        <h4>
          <strong>
            <span>五、数据储存</span>
          </strong>
        </h4>
        <span>1、百万麒麟不对用户在本服务中相关数据的删除或储存失败负责。</span>

        <span>2、百万麒麟可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</span>

        <span>3、如用户停止使用或注销账户后，百万麒麟可以从服务器上永久地删除用户的数据。本服务停止、终止后百万麒麟没有义务向用户返还任何数据。</span>

        <h4>
          <strong>
            <span>六、风险承担</span>
          </strong>
        </h4>

        <span>1、用户理解并同意，百万麒麟仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对百万麒麟及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险百万麒麟无法且不会对因用户行为而导致的任何损失或损害承担责任。</span>

        <span>如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向百万麒麟举报或投诉，百万麒麟将依本协议约定进行处理。</span>

        <span>2、用户理解并同意，因业务发展需要，百万麒麟保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。</span>

        <h4>
          <strong>
            <span>七、百万麒麟服务</span>
          </strong>
        </h4>

        <span>百万麒麟为用户提供媒体播放（视频）、供需市场、社交等相关服务各项服务。用户应当按照百万麒麟的服务规则和页面提示进行操作，也可以直接联系百万麒麟获取更多的服务。百万麒麟各单项服务协议与本协议冲突或是有特别规定的，应当优先适用各单项服务协议。</span>

        <span>百万麒麟的各项服务费用、道具消耗费用在百万麒麟上公布，百万麒麟有权根据有关政策的变化、市场情况、服务范围变化等原因，单方面调整价格标准，调整的项目将在百万麒麟上更新，不作额外的通知。百万麒麟有权拒绝为不按照百万麒麟要求提供信息或不服从百万麒麟服务规则的用户提供服务，有权注销或者冻结该用户账户。</span>

        <span>用户有权取回用户在百万麒麟账户中未使用的资金，但不得利用百万麒麟资金账户套现信用卡资金，不得有其他违反银行、支付中介机构及百万麒麟资金规范的行为。用户在百万麒麟账户消费的的订单将提供明细以供查询的资金不产生任何形式的孳息。</span>

        <span>百万麒麟的数据来源于互联网，版权归源网站所有，平台通过技术手段对信息进行汇总实现快照，无法避免后续网站内容的变更，汇总的数据如有出入本站不承担责任。</span>

        <span>百万麒麟没有为用户保存账户各项资料和记录的义务。</span>

        <h4>
          <strong>
            <span>八、百万麒麟免责条款</span>
          </strong>
        </h4>
        <p>
          <span>百万麒麟不保证提供的服务不受干扰、及时提供或免于出错。百万麒麟不保证所交易的商标具有适销性、适用性，也不能保证交易方实际拥有该商标。百万麒麟不对用户的真实身份进行核实。除非有效法律文书明确要求并提供相应保护，百万麒麟将遵守隐私政策，不对外提供用户信息。</span>
        </p>
        <p>
          <span>因下列情形无法按照约定提供各项服务时，百万麒麟不承担责任，包括但不限于：</span>
        </p>

        <span>（1）百万麒麟公告的休假或者系统维护的。</span>

        <span>（2）电信设备出现故障不能进行数据传输的。</span>

        <span>（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成百万麒麟障碍不能提供服务的。</span>

        <span>（4）由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟的。</span>

        <h4>
          <strong>
            <span>九、知识产权保护</span>
          </strong>
        </h4>

        <span>用户不得擅自在全球任何国家和地区申请与百万麒麟及其他服务的名称、标识、品牌、特色标志、域名相同或类似的商标、服务商标、域名、著作权等。用户不得擅自使用、复制、修改、改编、翻译、汇编、转载、发行百万麒麟所有内容，包括但不限于文字作品、图片作品、摄影作品、示意图、网站架构、网站画面的安排、网页设计&nbsp;项目架构、项目特色功能。</span>

        <h4>
          <strong>
            <span>十、隐私政策</span>
          </strong>
        </h4>

        <span>百万麒麟隐私政策构成本协议的有效内容。隐私政策调整的，适用最新的隐私政策。</span>

        <h4>
          <strong>
            <span>十一、法律适用和管辖</span>
          </strong>
        </h4>

        <span>本协议及其他单项服务协议适用中华人民共和国法律，法律没有明文规定的，按照百万麒麟的服务和交易惯例解释。协议的部分条款发生无效的情形，不受影响的其他条款和协议仍然有效。因本协议及其他单项服务协议产生的争议，浙江省杭州市拱墅区人民法院为有权管辖法院。</span>

        <h4>
          <strong>
            <span>十二、其他</span>
          </strong>
        </h4>

        <span>工作日是指百万麒麟的营业日。除非百万麒麟公告通知休假，一般为中华人民共和国适用的休息日以及法定节假日之外的正常工作日。日期的计算使用公历年月日，时间的计算使用北京时间。</span>

        <p>
          <span>百万麒麟通过公告通知，公告的时间为送达到用户的时间；通过百万麒麟网页、app、向用户提醒，提醒出现的时间为送达的时间；通过用户提供的联系方式向用户送达，用户或其代表签收、用户系统接收的时间为送达的时间。</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement'
};
</script>
<style scoped lang="scss">
.tab1 {
  padding: 0 40px;
  .tltle {
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin: 40px auto;
  }
  &_text {
    p {
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }
    span {
      font-size: 18px;
      color: #222222;
      display: block;
      padding: 20px 0 40px;
      line-height: 22px;
    }
    ul {
      padding-bottom: 36px;
      li {
        padding-top: 16px;
        font-size: 18px;
        color: #000000;
        position: relative;
        display: flex;
        line-height: 22px;
      }
      li > span {
        min-width: 10px;
        width: 10px;
        height: 10px;
        background-color: #000000;
        border-radius: 50%;
        display: block;
        padding: 0;
        margin-top: 8px;
        margin-right: 14px;
      }
    }
  }
}
</style>