import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件
import Home from '../views/Home.vue'
import Recommend from "../views/index/Recommend.vue"
import Discover from "../views/discover/Discover.vue"
import Dashboard from "../views/dashboard/Dashboard.vue"
import Hot from "../views/hot/Hot.vue"
import UserInfo from "../views/dashboard/UserInfo.vue"
import Record from "../views/me/Record.vue"
import Follow from "../views/follow/Follow.vue"
import PlayerVideo from "../views/player/player.vue"
import AboutUs from "../views/AboutUs.vue"

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      { path: "", component: Dashboard }, // 首页
      { path: "Discover", component: Discover }, // 搜索
      { path: "Dashboard", component: Dashboard }, // 首页
      // path 为空，表示默认展示Recommend组件
      { path: "Recommend", component: Recommend }, // 推荐
      { path: "Follow", component: Follow }, // 关注
      { path: "Hot", component: Hot }, // 热点
      { path: "/user", component: UserInfo }, // 用户
      { path: "/me", component: Record }, // 购买记录
      {
        path: '/home', // 首页
        name: 'home',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/home5/:id',
        name: 'home5',
        props: true,
        component: () => import('../views/Home5.vue')
      },
      {
        path: '/aboutUs', //关于我们
        name: 'aboutUs',
        component: () => import('../views/AboutUs.vue')
      },
      {
        path: '/nash', //招贤纳士
        name: 'nash',
        component: () => import('../views/Nash.vue')
      },
      {
        path: '/contactUs', //联系我们
        name: 'contactUs',
        component: () => import('../views/ContactUs.vue')
      }
    ]
  },
  { 
    path: "/Home5", 
    name:"Home5",
    component: AboutUs 
  }, 
  {
    path: '/player',
    name: "Player",
    component: PlayerVideo
  }, 
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: () => import('@/components/layout/Index.vue'),
  //   redirect: 'home',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //   ]
  // },
  {
    path: '/video', //创作者平台
    name: 'video',
    component: () => import('@/components/layout/CreatorPlatform.vue'),
    redirect: 'uploadVideo',
    children: [{
      path: '/uploadVideo',
      name: 'uploadVideo',
      component: () => import('../views/uploadVideo/Index.vue')
    },
    {
      path: '/postVideo',
      name: 'postVideo',
      component: () => import('../views/uploadVideo/PostVideo.vue')
    },
    {
      path: '/adminVideo',
      name: 'adminVideo',
      component: () => import('../views/adminVideo/Index.vue')
    },
    {
      path: '/addQuestions',
      name: 'addQuestions',
      component: () => import('../views/adminVideo/AddQuestions.vue')
    },
    {
      path: '/collectionAdmin',
      name: 'collectionAdmin',
      component: () => import('../views/collectionAdmin/Index.vue')
    },
    {
      path: '/viewCollection',
      name: 'viewCollection',
      component: () => import('../views/collectionAdmin/ViewCollection.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/collectionAdmin/News.vue')
    },
    {
      path: '/managementQuestions',
      name: 'managementQuestions',
      component: () => import('../views/collectionAdmin/ManagementQuestions.vue')
    }
    ]
  },
  {
    path: '/editVideo',
    name: 'editVideo',
    component: () => import('../views/uploadVideo/EditVideo.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/auth/redirect.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});
export default router





// router.beforeEach((to, from, next) => {
//   const meta = to.matched[0].meta;
//   if (!meta.requiresAuth) {
//     const userData = localStorage.getItem('userData');
//     if (userData) {
//       next()
//     } else {
//       Message("请先登录创作者平台");
//       store.commit('isLogin', true);
//       next("/");
//     }
//   } else {
//     next()
//   }
// })
