import axios from "axios";

function generateInstance(baseURL) {
  // 请求根路径
  const instance = axios.create({
    baseURL
  });

  instance.interceptors.request.use(
    (response) => {
      if (localStorage.getItem("userData") != null) {
        const tk = JSON.parse(localStorage.getItem("userData")).token;
        //alert(tk);
        if (tk) {
          response.headers.token = tk;
        }
      }

      return response;
    },
    (error) => {
      // 处理错误响应
      //alert(2);
      return Promise.reject(error);
    }
  );
  // 响应拦截器
  instance.interceptors.response.use(
    // 请求成功
    (res) => {
      if (res.status === 200) {
        return Promise.resolve(res.data);
      } else {
        //alert(res.status)
        return Promise.reject(res);
      }
    },
    // 请求失败
    (error) => {
      alert("请登录");
      return Promise.reject(error);
    }
  );
  return instance;
}

export const  qilinTool=generateInstance('https://api.baiwanqilin.com/qilin-tool/v1')
export const  qilinUser=generateInstance('https://api.baiwanqilin.com/qilin-user/v1')
export const  qilinMedia=generateInstance('https://api.baiwanqilin.com/qilin-media/v1')
