import Vue from 'vue';
import Vuex from 'vuex';
import { equipment, localStorages } from '@/utils/public';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    equipment,
    userData: localStorages('userData'),
    videoFile: null,
    getVoucherData: null,
    loginChangBox: false,
    videoHighlight: null,
    isLogin: false
  },
  getters: {
    userData: state => state.userData,
    videoFile: state => state.videoFile
  },
  mutations: {
    videoFile: (state, file) => (state.videoFile = file),
    userData: (state, userData) => (state.userData = userData),
    loginChangBox: (state, b) => (state.loginChangBox = b),
    getVoucherData: (state, voucher) => (state.getVoucherData = voucher),
    videoHighlight: (state, voucher) => (state.videoHighlight = voucher),
    isLogin: (state, booleanValue) => (state.isLogin = booleanValue)
  },
  actions: {}
});
