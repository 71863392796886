<template>
  <div style="margin: 0 auto">
    <el-row :gutter="20" style="display: flex;">
      <el-col :span="18">
        <Slide/>
      </el-col>
      <el-col :span="6">
        <User/>
      </el-col>
    </el-row>
    <el-row v-if="userData" :gutter="20" style="padding-top: 20px;">
      <el-col :span="24">
        <Study/>
      </el-col>
    </el-row>
    
    <el-row  :gutter="20" style="padding-top: 20px;">
      <el-col :span="24">
        <Course />
      </el-col>
    </el-row>
    <el-row  :gutter="20" style="padding-top: 20px;">
      <el-col :span="24">
        <Teacher/>
      </el-col>
    </el-row>
    <el-row  :gutter="20" style="padding-top: 20px;">
      <el-col :span="24">
        <Recommend/>
      </el-col>
    </el-row>
    <div>
      
    </div>
  </div>
</template>

<script>
import User from "./User";
import Slide from "./Slide";
import Study from "./Study";
import Course from "./Course";
import Teacher from "./Teacher";
import Recommend from "./Recommend";

// import user from '../../http/https-user'

export default {
  components:{User,Slide,Study,Teacher,Recommend,Course},
  name: "dashboard",
  data() {
    return {
      userData:null
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));

 
    // const {
    //   data: { data :getTeacherList },
    // } = await user.get("/user/getTeacherList?pageNum=1&pageSize=10 ");
    // console.log(getTeacherList)

  },
  methods: {
    goLogin(){
      this.$store.commit("isLogin", true);
    }
  },
};
</script>
