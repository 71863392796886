<template>
  <div>
    <h4 style="background: #f5f7fa; padding: 10px; color: rgb(22 23 34)">
      为你推荐
    </h4>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane v-for="(category,index) in categoryList" :label="category.name" :key="index">
        <div v-if="videoList.length > 0">
          <el-row :gutter="10">
            <el-col
              :span="6"
              v-for="(video) in videoList"
              :key="video.videoId"
              style="position: relative"
            >
              <el-card :body-style="{ padding: '0px' }">
                <a :href="'/#/player?id='+video.videoId" @click="toPlayer(video)" target="_blank">
                  <el-image
                    style="width: 200px; height: 200px"
                    :src="video.videoImg"
                    :alt="video.videoTitle"
                    fit="cover"
                  ></el-image>
                  <el-tag
                    style="position: absolute;
                      right: 10px;
                      top: 10px;
                      color: #000;
                    "
                    type="info"
                    >{{
                      getDuration(
                        video.videoDuration || video.videoEditingDuration
                      )
                    }}</el-tag
                  >
                  <div style="padding: 0 14px 0 14px; color: #000">
                    <div style="font-size: 10px" :title="video.userTitle">
                      @{{ video.username }}
                    </div>
                    <div style="height: 50px; overflow: hidden">
                      {{ video.videoTitle }}
                    </div>
                  </div>
                </a>
              </el-card>
            </el-col>
          </el-row>
          <div style="padding-top: 20px; margin-left: -10px">
            <el-pagination
              @current-change="currentChange"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import axios from "@/http/https";
var qs = require('qs');

export default {
  name: "recommend",
  data() {
    return {
      categoryId:null,
      pageNum: 1,
      pageSize: 4,
      total: 40,
      userId: null,
      currentPath: 0,
      videoList: [],
      categoryList: [],
    };
  },
  async created() {
    const { data } = await axios.get("/category/list");
    this.categoryList = data;
    this.loadData();
  },
  methods: {
    currentChange() {
      this.loadData();
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.categoryId = this.categoryList[parseInt(tab.index)].id;
      this.loadData();
    },
    async loadData() {
      const { pageNum, pageSize, categoryId } = this;
      var parm=qs.stringify({
        pageNum,
        pageSize:pageSize*2,
        categoryId,
            })
      const { data } = await axios.post("/cecommend/recommendVideo", parm,{
            Headers:{
                'token':sessionStorage.getItem("utoken")
            }
        });
      // if (data.length < this.pageSize) {
      //   this.total = this.pageNum * this.pageSize;
      // }
      this.videoList = data;
    },
    getDuration(videoDuration) {
      var mini = videoDuration % 60;
      if (mini < 10) {
        mini = "0" + mini;
      }
      return parseInt(videoDuration / 60) + ":" + mini;
    },
    toPlayer(obj) {
      localStorage.setItem("rowData", JSON.stringify(obj));
      localStorage.setItem("categoryId", this.categoryId);
    },
  },
};
</script>
