<template>
  <el-tabs type="border-card" @tab-click="handleClick">
    <el-tab-pane v-for="(item,index) in items" :label="item.label" :key="index">
      <div v-if="teacherList.length > 0">
        <el-row>
          <el-col
            :span="4"
            v-for="(teacher,index) in teacherList"
            :key="index"
            style="position: relative"
          >
            <el-card :body-style="{ padding: '0px' }">
              <a :href="'/#/user?otherId='+teacher.userId" >
                <el-image
                  style="max-width: 100%; height: 200px"
                  :src="teacher.userImg"
                  fit="cover"
                ></el-image>
                <div style="padding: 14px;color: #000;" >
                  {{ teacher.userName }}
                </div>
              </a>
            </el-card>
          </el-col>
        </el-row>
        <div style="padding-top: 20px; margin-left: -10px">
          <el-pagination
            @current-change="currentChange"
            :page-size.sync="pageSize"
            :current-page.sync="pageNum"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import axios from "@/http/https-user";

export default {
  name: "teacher",
  data() {
    return {
      pageNum: 1,
      pageSize: 12,
      total: 120,
      currentPath: 0,
      userData: null,
      teacherList: [],

      items: [
        { label: "为你推荐（老师展示）", path: "/user/getTeacherList" },
      ],
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.currentPath = this.items[0].path;
    await this.loadData()
  },
  methods: {
    currentChange() {
      this.loadData();
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.currentPath = this.items[parseInt(tab.index)].path;
      this.loadData();
    },
    async loadData() {
      const { pageNum, pageSize } = this;
      const { data:{list} } = await axios.get(this.currentPath, {
        params: { pageNum, pageSize },
      });
      if (list.length < 12) {
        this.total = this.pageNum * this.pageSize;
      }
      this.teacherList = list;
    }
  },
};
</script>
