<template>
    <div class="hd_search">
        
        <input v-model="selkey" type="text" prop="identifier" :rules="[{ required: true, message: '搜索你感兴趣的内容', trigger: 'blur' }]" placeholder="搜索你感兴趣的内容" />
        <!--<el-form-item prop="identifier" :rules="[{ required: true, message: '搜索你感兴趣的内容', trigger: 'blur' }]">
            <el-input v-model="ruleForm.identifier" placeholder="输入账号" autocomplete="off" :class="ruleForm.identifier"></el-input>
          </el-form-item>-->
        <button class="search_btn" @click="submitForm">
            <span>搜索</span>
        </button>
    </div>
</template>

<script>
export default {
    data(){
        return{
            selkey:""
        }
    },
    methods:{
    submitForm() {
        if(this.selkey=="")
        {
            alert("请输入搜索内容");
            return;
        }
        window.location.href="/#/discover?skey="+this.selkey
       
      //alert(this.selkey);
    }
}
};
</script>

<style lang="less" scoped>
.hd_search {
    display: flex;
    margin: 10px 30px;
    width: 35.49%;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
    background-color: #292b35;
    input {
        // width: calc(100% - 93px);
        // height: 100%;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        font-family: PingFang SC,DFPKingGothicGB-Regular,sans-serif;
        color: rgba(255,255,255,.9);
        padding: 0 12px;
        transition: all 0.2s;
        // 光标颜色
        caret-color: red;
    }
    input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
    .search_btn {
        width: 69px;
        height: 100%;
        background-color: #fe2c55;
        color: #fff;
        font-size: 16px;
        font-family: "PingFang SC", "Lantinghei SC", "Microsoft YaHei",
            "Helvetica Neue", "Open Sans", Arial, "Hiragino Sans GB", sans-serif;
        transition: all 0.2s;
        cursor: pointer;
    }
    .search_btn:hover {
        background-color: #f22a51;
    }
}
.hd_search:hover input {
    background-color: #40424b;
}
</style>