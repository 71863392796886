<template>
  <div>
    <div class="tab1">
      <div class="tltle">“百万麒麟”隐私政策</div>
      <div class="tab1_text">
        更新日期：2021年7月9日
        <span>郑州百万麒麟科技有限公司及其关联方（简称"我们"）作为百万麒麟的运营者，深知个人信息对你的重要性，我们将按照法律法规的规定，保护你的个人信息及隐私安全。我们制定本"隐私政策"并特别提示：希望你在使用百万麒麟及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</span>

        <span>本隐私政策将帮助你了解：</span>
        <ul>
          <li>
            <span></span>我们会遵循隐私政策收集、使用你的信息，但不会仅因你同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。
          </li>
          <li>
            <span></span>当你使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，你均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明哪些是必要信息。
          </li>
          <li>
            <span></span>如果你未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果你登录了帐号，我们会根据帐号信息实现信息推送。
          </li>
          <li>
            <span></span>为了帮你发现更多朋友，我们可能会申请通讯录权限。
          </li>
          <li>
            <span></span>我们尊重你的选择权，如果你不希望被推荐给朋友，你可以在"我—设置—隐私设置"中随时关闭。
          </li>
          <li>
            <span></span>通讯录、精确地理位置、摄像头、麦克风、相册、设备标识权限，均不会默认开启，只有经过你的明示授权才会在为实现特定功能或服务时使用，你也可以撤回授权。特别需要指出的是，即使经过你的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集你的信息。
            百万麒麟权限申请与使用情况说明
          </li>
          <li>
            <span></span>本隐私政策适用于你通过百万麒麟应用程序（包括小程序等不同版本）、百万麒麟官方网站、供第三方网站和应用程序使用的百万麒麟软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。
          </li>
        </ul>
        <span>下文将帮你详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮你了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关你个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。</span>
        <h4>1、我们如何收集和使用个人信息</h4>
        <h4>2、我们如何使用Cookie等同类技术</h4>
        <h4>3、我们如何共享、转让、公开披露个人信息</h4>
        <h4>4、我们如何存储个人信息</h4>
        <h4>5、我们如何保护个人信息的安全</h4>
        <h4>6、个性化推荐</h4>
        <h4>7、管理你的个人信息</h4>
        <h4>8、未成年人条款</h4>
        <h4>9、隐私政策的修订和通知</h4>
        <h4>10、联系我们</h4>
      </div>
      <div class="tab1_text">
        <p>
          <span>1、我们如何收集和使用个人信息</span>
        </p>
        <span>我们会按照如下方式收集你在使用服务时主动提供的，以及通过自动化手段收集你在使用功能或接受服务过程中产生的信息：</span>
        1.1 注册、登录、认证
        <br />1.1.1 注册、登录帐号
        <br />a.当你注册、登录百万麒麟及相关服务时，你可以通过手机号创建帐号，并且你可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助你完成注册。你还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善你的信息，并在填写后自主选择展示范围。
        <br />b.你也可以使用第三方帐号登录并使用百万麒麟，你将授权我们获取你在第三方平台注册的公开信息（头像、昵称以及你授权的其他信息），用于与百万麒麟帐号绑定，使你可以直接登录并使用本产品和相关服务。在使用第三方账号进行登录时，可能需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用，不会收集你的隐私信息。
        <br />c.登录第三方帐号：当你使用百万麒麟帐号登录第三方帐号时，经过你的同意，我们可能会共享昵称、头像以及你授权的其他信息。
        <br />d.基于我们与通信运营商的合作，当你使用百万麒麟“一键登录”功能时，经过你的明示同意，运营商会将你的手机号码发送给我们，便于我们为你提供快捷的登录服务。手机号码属于个人敏感信息，如果拒绝提供将无法使用“一键登录”方式注册登录百万麒麟，但不影响你通过其他方式注册登录，也不影响其他功能的正常使用。
        <br />1.1.2 认证
        <br />在你使用身份认证的功能或相关服务所需时，根据相关法律法规，你可能需要提供你的真实身份信息（电话号码等）以完成实名验证。
        部分信息属于个人敏感信息，你可以拒绝提供，如果拒绝提供你将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
        <br />1.2 播放浏览
        <br />a.为保障推荐内容的质量并向你推荐可能感兴趣的视频及相关信息，百万麒麟可能会收集相关必要的日志信息。
        <br />b.上述日志信息包括：
        <br />你操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享
        <br />你主动提供的信息：反馈、发布、点赞（喜欢）、评论
        <br />地理位置信息：含通过GPS信息、WLAN接入点、蓝牙和基站等传感器信息获取到的精确地理位置信息及通过网络位置信息（例如基站、IP及WLAN）获取的大致地理位置信息
        <br />c.精确地理位置信息是个人敏感信息，若你拒绝提供，我们将不会根据精确地理位置信息向你推荐信息，且不会影响百万麒麟其他功能的正常使用。
        <br />d.我们会基于大致地理位置信息对应的城市及/或区县向你展示、推荐你所在地区（城市、区县等）的本地相关信息。
        <br />1.3 发布与互动
        <br />a.你发布的音频图片或进行评论后通过后台审核后
        平台将通过极光第三方sdk进行消息推送，提示你你的互动与发布内容后续结果，如果不使用情况下可在设置中屏蔽我司APP
        但APP内则会继续推送该互动后续结果
        但不在通过APP外进行推送，在设置中屏蔽我司APP我司则视为你不想使用该推送
        如错过重要内容 导致你收到损失，我司不承担任何责任
        <br />b.聊天设置中发送定位
        在你同意授权获取当前位置后可使用该功能，我司提供百度sdk为您进行实时定位，你可以拒绝提供，如果拒绝提供你将无法获得相关服务，但不影响其他功能与服务的正常使用。
        <br />1.3.1 信息发布
        <br />a.你发布音视频、图片等内容或进行评论时，我们将收集你发布的信息，并展示你的昵称、头像、发布内容和信息。
        <br />b.你使用发布图片、音视频功能时，我们会请求你授权相机、照片、麦克风权限。你如果拒绝授权提供，将无法使用此功能，但不影响你正常使用百万麒麟的其他功能。
        <br />c.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有你的部分信息（如：在评论、留言、发布图文、音视频中涉及到与你相关的信息）。
        <br />d.我们非常重视用户的使用体验，为了提升音视频的上传速度、丰富发布功能和优化体验，当你发布音视频时，在点击“发布”确认上传之前，我们会将该音视频临时加载至服务器。如果你取消或因其他原因未成功上传，我们会立即删除相关音视频。
        <br />e.我们还可能为向你提供人脸贴纸功能而在客户端本地使用仅供该功能的部分面部特征值信息，以提供更好的视觉效果，在此过程中，除非另有明确说明并征得你的同意，我们不会回传、储存或共享你的任何面部特征值信息。
        <br />1.3.2 互动交流
        <br />a.当你关注或分享帐号，或进行浏览、评论、收藏、搜索、喜欢（点赞）或分享时，我们会收集你前述信息，并展示相应内容发布者的帐号发布的内容，或为你推荐相应内容发布者。
        <br />b.你可以在百万麒麟中添加或删除朋友，为帮你实现这一功能，我们会创建朋友列表信息。朋友列表是建立与管理朋友关系所必需的必要信息。
        <br />1.3.3 朋友推荐
        <br />a.百万麒麟可能会根据你及其他用户授权的手机号码、通讯录、关注、粉丝等关系、你及其他用户在个人主页选择对外展示的资料、你及其他用户的互动行为为用户推荐朋友。
        <br />b.我们可能会请求通讯录权限，并将通讯录中的信息进行高强度加密算法处理后，用于向用户推荐通讯录中的朋友。通讯录信息属于个人敏感信息，拒绝提供该信息仅会使用户无法使用上述功能，但不影响用户正常使用百万麒麟及相关服务的其他功能。请知悉，如果其他用户上传的通讯录中包含你的联系方式等信息，系统也可能会基于该信息向你推荐朋友或者把你推荐给相应用户。
        <br />c.百万麒麟可能会基于用户授权的精准地理位置信息向用户推荐附近的人。精确地理位置信息是个人敏感信息，拒绝提供该信息将会影响该功能，但不影响用户正常使用百万麒麟的其他功能。
        <br />1.3.4 消息通知
        <br />你知悉并同意，对于你在使用产品及/或服务的过程中提供的你的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途；此外，我们也可能会以短信、电话的方式，为你提供你可能感兴趣的服务、功能或活动等商业性信息的用途，但请你放心，如你不愿接受这些信息，你可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
        <br />1.4 搜索
        <br />你使用百万麒麟的搜索服务时，我们会收集你的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在你的本地存储设备之中，并可向你展示搜索结果内容、搜索历史记录。
        <br />1.5 运营与安全运行
        <br />1.5.1 运营与安全保障
        <br />我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。
        <br />1.5.2 设备信息与日志信息
        <br />a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP
        地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。
        <br />b.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
        <br />c.我们可能使用你的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得你授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。
        <br />1.6 分享与活动参与
        <br />在你分享或接收被分享的信息、参加活动等情形下，我们需要访问你的剪切板，读取其中链接，以实现跳转、分享、活动联动等功能或服务。
        <br />1.7 统计分析
        <br />为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请你放心，我们不会收集你的隐私信息。
        <br />1.8 收集、使用个人信息目的变更
        <br />请你了解，随着我们业务的发展，可能会对百万麒麟的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用你的个人信息，会再次进行告知，并征得你的同意。
        <br />1.9 依法豁免征得同意收集和使用的个人信息
        <br />请你理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用你的个人信息无需征得你的授权同意：
        <br />a.与国家安全、国防安全直接相关的；
        <br />b.与公共安全、公共卫生、重大公共利益直接相关的；
        <br />c.与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />e.所收集的你的个人信息是你自行向社会公众公开的；
        <br />f.从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br />g.根据你的要求签订或履行合同所必需的；
        <br />h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
        <br />i.为合法的新闻报道所必需的；
        <br />j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        <br />k.法律法规规定的其他情形。
        <br />特别提示你注意，如信息无法单独或结合其他信息识别到你的个人身份，其不属于法律意义上你的个人信息；当你的信息可以单独或结合其他信息识别到你的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他你的个人信息结合使用时，这些信息在结合使用期间，将作为你的个人信息按照本隐私政策处理与保护。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>2、我们如何使用Cookie等同类技术</span>
        </p>Cookie
        和设备信息标识等同类技术是互联网中普遍使用的技术。当你使用百万麒麟及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个Cookie
        或匿名标识符，以收集、标识你访问、使用本产品时的信息。我们承诺，不会将Cookie
        用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie
        和同类技术主要为了实现以下功能或服务：
        <br />2.1 保障产品与服务的安全、高效运转
        <br />我们可能会设置认证与保障安全性的Cookie
        或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
        <br />2.2 帮助你获得更轻松的访问体验
        <br />使用此类技术可以帮助你省去重复你填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。
        <br />2.3 为你推荐、展示、推送你可能感兴趣的内容或帐号
        <br />a.我们可能会利用此类技术了解你的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息或功能，并优化你对广告的选择。
        <br />b.在百万麒麟的分享页中，我们可能会使用Cookie对浏览活动进行记录，用于向你推荐信息和排查崩溃、延迟的相关异常情况以及探索更好的服务方式。
        <br />2.4 Cookie的清除
        <br />大多数浏览器均为用户提供了清除浏览器缓存数据的功能，你可以在浏览器设置功能中进行相应的数据清除操作。如你进行清除，你可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。
      </div>
      <div class="tab1_text">
        <p>
          <span>3、我们如何共享、转让、公开披露个人信息</span>
        </p>3.1.1 共享原则
        <br />a.授权同意原则：
        未经你的同意，我们不会共享你的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得你的同意。
        <br />b.合法正当与最小必要原则：
        共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。
        <br />c.安全审慎原则：
        我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。可点击查看平台公示的第三方SDK目录（该目录表明了使用第三方sdk目录所需要获取的权限及使用用途）
        <br />3.1.2 实现功能或服务的共享信息
        <br />a.当你在使用百万麒麟中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为你提供服务时我们会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计并通过算法做特征与偏好分析，形成间接人群画像，用以向你进行推荐、展示或推送你可能感兴趣的信息，或者推送更适合你的特定功能、服务或商业广告。
        <br />b.登录、绑定其他第三方帐号：当你使用百万麒麟帐号登录其他第三方的产品或服务时，或将百万麒麟帐号与其他第三方帐号绑定，经过你的同意，我们会将你的昵称、头像、直接或间接关注、粉丝等通讯关系及其他你授权的信息与前述产品或服务共享。你可以在【设置】-【帐号与安全】-【授权管理】中管理你对其他第三方产品或服务的授权管理，或在【设置】-【帐号与安全】-【第三方帐号绑定】中管理你绑定的第三方帐号。请你注意，在你取消对其他第三方产品或服务的授权后，你可能无法使用已取消授权的百万麒麟帐号再次登录该第三方产品或服务；如需登录，可能需要你再次授权。
        <br />c.小程序：当你使用小程序时，未经你同意，我们不会向这些开发者、运营者共享你的个人信息。当你使用小程序时，小程序可能会使用你授权的相关系统权限，你可以在小程序中撤回授权。
        <br />d.地理位置服务：当你使用地理位置相关服务时，我们会通过SDK或相关技术将GPS信息与位置服务提供商（百度地图）进行共享以便可以向你返回位置结果。GPS信息是个人敏感信息，拒绝提供，仅会影响地理位置服务功能，但不影响其他功能的正常使用。
        <br />e.钱包功能：钱包功能中的相关服务由我们的关联方及合作伙伴提供，当你使用钱包相关功能时我们的关联方和合作伙伴会根据功能或服务必需而收集必要限度内的信息。
        <br />f.支付功能：支付功能由与我们合作的第三方支付机构向你提供服务。第三方支付机构可能需要收集你的姓名、银行卡类型及卡号、有效期及手机号码。银行卡号、有效期及手机号码
        是个人敏感信息，这些信息是支付功能所必需的信息，拒绝提供将导致你无法使用该功能，但不影响其他功能的正常使用。
        <br />g.账单功能：当你使用百万麒麟账单中心功能时，经过你的授权，我们可能会与下单的相关服务提供方共享你的订单信息以方便你统一查询订单信息。
        <br />h.为与你使用的终端机型适配消息推送功能，我们可能会通过SDK等技术与终端设备制造商（华为、小米、OPPO、VIVO等）共享手机型号、版本及相关设备信息。
        <br />3.1.3 实现安全与分析统计的共享信息
        <br />a.保障使用安全：我们非常重视帐号、服务及内容安全，为保障你和其他用户的帐号与财产安全，使你和我们的正当合法权益免受不法侵害，我们和关联方或服务提供商可能会共享必要的设备、帐号及日志信息。
        <br />b.分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别你的个人身份。
        <br />c.学术研究与科研：为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与科研院所、高校等机构共享去标识化或匿名化的数据。
        <br />3.1.4 帮助你参加营销推广活动
        <br />当你选择参加我们及我们的关联方或第三方举办的有关营销活动时，可能需要你提供姓名、通信地址、联系方式、银行帐号等信息。其中部分信息是个人敏感信息，拒绝提供可能会影响你参加相关活动，但不会影响其他功能。只有经过你的同意，我们才会将这些信息与关联方或第三方共享，以保障你在联合活动中获得体验一致的服务，或委托第三方及时向你兑现奖励。
        <br />3.2 转让
        <br />a.我们不会转让你的个人信息给任何其他第三方，除非征得你的明确同意。
        <br />b.随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护你的个人信息，否则我们将要求继受方重新征得你的授权同意。
        <br />3.3 公开展示
        <br />a.
        我们不会公开披露你的信息，除非遵循国家法律法规规定或者获得你的同意。我们公开披露你的个人信息会采用符合行业内标准的安全保护措施。
        <br />b. 对违规帐号、欺诈行为进行处罚公告时，我们会披露相关帐号的信息。
        <br />c.当你授权游戏或小程序后，你和朋友的头像、昵称将会在排名及相关功能中展示。
        <br />d.你公开发布的作品、动态、喜欢（点赞）将会在个人主页中展示。
        <br />e.你可以在【设置】-【隐私设置】-【私密帐号】中选择是否开启“私密帐号”功能。开启后，未经你的同意，我们不会将你发布的内容和点击“喜欢”的状态在其他软件中展示。只有你批准的用户才能关注你，并能看到你的内容和喜欢（点赞），但现有的粉丝不会受到影响。
        <br />f.你可以在【设置】-【隐私设置】-【谁可以看我的喜欢列表】中选择是否让其他用户看到自己的喜欢（点赞）列表。
        <br />g.你可以在【设置】-【隐私设置】-【谁可以看我的关注、粉丝列表】中选择是否让其他用户看到你的关注、粉丝列表。
        <br />3.4 依法豁免征得同意共享、转让、公开披露的个人信息
        <br />请你理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露你的个人信息无需征得你的授权同意：
        <br />a.与国家安全、国防安全直接相关的；
        <br />b.与公共安全、公共卫生、重大公共利益直接相关的；
        <br />c.与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />d.出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />e.你自行向社会公众公开的个人信息；
        <br />f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>4、我们如何存储个人信息</span>
        </p>4.1 存储地点
        <br />我们依照法律法规的规定，将在境内运营过程中收集和产生的你的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求你的同意。
        <br />4.2 存储期限
        <br />我们仅在为提供百万麒麟及服务之目的所必需的期间内保留你的个人信息：你发布的信息、评论、喜欢（点赞）及相关信息，在你未撤回、删除或未注销帐号期间，我们会保留相关信息。超出必要期限后，我们将对你的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>5、我们如何保护个人信息的安全</span>
        </p>a.我们非常重视你个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护你的个人信息，防止你提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。目前，百万麒麟已经通过了国家信息安全等级保护（三级）的测评和备案，并通过了国际权威的ISO27001信息安全认证及ISO27701隐私信息管理认证。
        <br />b.我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护你的个人信息，并使用安全保护机制防止你的个人信息遭到恶意攻击。
        <br />c.我们会建立专门的安全部门、安全管理制度、数据安全流程保障你的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息，并适时对数据和技术进行安全审计。
        <br />d.尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。
        <br />e.你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。
        <br />f.我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
        <br />g.你一旦离开百万麒麟及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护你在百万麒麟及相关服务之外的软件、网站提交的任何个人信息，无论你登录、浏览或使用上述软件、网站是否基于百万麒麟的链接或引导。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>6、管理你的个人信息</span>
        </p>我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的查询、访问、修改、删除、撤回同意授权、注销帐号、投诉举报以及设置隐私功能的相关权利，以使你有能力保障你的隐私和信息安全。
        <br />6.1.1 个人爱好隐私设置
        <br />你可以在【我】-【设置】-【隐私设置】选择是否可以查看你的浏览爱好及收藏内容
        <br />6.1.2 改变或撤回敏感权限设置
        <br />a.你可以在设备本身操作系统中关闭精确地理位置信息、通讯录、摄像头、麦克风、相册权限、日历权限，改变同意范围或撤回你的授权。撤回授权后我们将不再收集与这些权限相关信息。
        <br />b.
        当你使用小游戏、小程序后，你可以在小游戏、小程序界面中管理小程序、小游戏获得的应用权限，你可以撤回对小程序、小游戏的应用权限授权。
        <br />6.1.3 改变或撤回授权的信息处理
        <br />特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供撤回同意或授权所对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响我们此前基于你的授权而开展的个人信息处理。
        6.2 访问、删除、更正你的个人信息
        <br />6.2.1 访问个人帐号信息
        <br />你可以查询、访问你的头像、用户名、简介、性别、生日、地区，你可以在【我】-【编辑资料】进行查询、访问、更正或调整可见范围。
        <br />6.2.2 查询访问、更正、删除信息
        <br />a.进入【我】-【喜欢】中访问、取消你点赞（喜欢）的信息
        <br />b.进入【关注】可以访问、查询关注的帐号信息，在关注列表点击相应帐号后的“已关注”按钮（如有）或进入相应关注帐号主页可以选择取消关注该帐号
        <br />c.进入【我】-【设置】-【帐号与安全】访问百万麒麟号、百万麒麟码等信息、访问更正绑定的手机号、第三方帐号、设置、更正百万麒麟密码、访问实名认证信息、登录设备信息
        <br />d.出于安全性和身份识别的考虑，你可能无法自主修改注册时提交的某些初始注册信息（如实名认证后信息）；如你确有必要修改该类信息，请你通过电子邮件（baiwanqilin1@163.com）联系我们，我们将尽快审核所涉问题，并由专人验证你的用户身份后及时予以回复。
        6.2.3 控制信息可见范围
        <br />a.进入【我】-【设置】-【隐私设置】-【点赞】中控制他人是否在你的个人主页中的喜欢列表中看到你喜欢（点赞）的信息，或控制你的作品下的点赞列表及你的点赞信息在他人作品下的可见范围
        <br />b.进入【我】-【设置】-【隐私设置】-【黑名单】控制他人是否可以与你通讯、是否可以查看你的帐号内容及点赞信息
        <br />c.进入【我】-【设置】-【隐私设置】-【关注与粉丝列表】中控制他人是否可以看到你的关注、粉丝列表
        <br />6.3 注销帐号
        <br />你可以在【我】-【设置】-【帐号与安全】-【账号关联】进行帐号注销。在你注销帐号前，我们将验证你的个人身份、安全状态、设备信息等。有关注销的流程和内容，请参见注销页中信息确认。并点击我同意《账号注销协议》知悉并理解，注销帐号的行为是不可逆的行为，当你注销帐号后，我们将删除有关你的相关信息或进行匿名化处理，但法律法规另有规定的除外。
        <br />6.4 投诉举报
        <br />你可以按照我们公示的制度进行投诉或举报。如果你认为你的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，你可以在【我】-【设置】-【反馈与帮助】，进入用户反馈界面与我们联系。我们核查后会在15个工作日内反馈你的投诉与举报。
        <br />6.5 访问隐私政策
        <br />a.你可以在注册页面，或者在登录个人帐号【我】-【设置】-
        【隐私政策】查看本隐私政策的全部内容。
        <br />b.请你了解，本隐私政策中所述的百万麒麟及相关服务可能会根据你所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以你所使用的百万麒麟软件及相关服务为准。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>7、停止运营向你告知</span>
        </p>
        <br />如我们停止运营，我们将及时停止收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对所持有的你的个人信息进行删除或匿名化处理。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>8、未成年注意事项</span>
        </p>a.若你是未满18周岁的未成年人，在使用抖音及相关服务前，应在你的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
        <br />b.我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。同时我们建立了严格的未成年人信息收集使用规则，以保护儿童和青少年个人信息安全，你可以通过阅读《儿童/青少年使用须知》了解更具体内容。
        <br />c.若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>9、隐私政策的修订和通知</span>
        </p>a.为了给你提供更好的服务，百万麒麟及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经你明确同意，我们不会削减你依据当前生效的本隐私政策所应享受的权利。
        <br />b.本隐私政策更新后，我们会在百万麒麟发出更新版本，并在更新后的条款生效前通过官方网站（https://www.baiwanqilin.com）公告或其他适当的方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。
        <br />
      </div>
      <div class="tab1_text">
        <p>
          <span>10、联系我们</span>
        </p>如果你对个人信息保护问题有投诉、建议、疑问，你可以将问题发送至（baiwanqilin@163.com）或邮寄至下列地址：
        <br />河南省郑州市惠济区开元路水映唐庄49-2栋
        <br />我们将尽快审核所涉问题，并在验证你的用户身份后的十五个工作日内回复
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'policy'
};
</script>
<style scoped lang="scss">
.tab1 {
  padding: 0 40px;
  .tltle {
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin: 40px auto;
  }
  &_text {
    p {
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }
    span {
      font-size: 18px;
      color: #222222;
      display: block;
      padding: 20px 0 40px;
      line-height: 22px;
    }
    ul {
      padding-bottom: 36px;
      li {
        padding-top: 16px;
        font-size: 18px;
        color: #000000;
        position: relative;
        display: flex;
        line-height: 22px;
      }
      li > span {
        min-width: 10px;
        width: 10px;
        height: 10px;
        background-color: #000000;
        border-radius: 50%;
        display: block;
        padding: 0;
        margin-top: 8px;
        margin-right: 14px;
      }
    }
  }
}
</style>