<template>
  <el-tabs type="border-card" @tab-click="handleClick">
    <el-tab-pane
      v-for="(item, index) in items"
      :label="item.label"
      :key="index"
    >
      <div v-if="list.length > 0">
        <el-row>
          <el-col
            :span="4"
            v-for="(listItem, index) in list"
            :key="index"
            style="position: relative"
          >
            <el-card :body-style="{ padding: '0px' }" style="margin: 10px;">
              <a :href="'/#/user?otherId=' + listItem.userId">
                <el-image
                  style="max-width: 100%; height: 200px"
                  :src="listItem.img"
                  fit="cover"
                ></el-image>

                <div style="padding: 14px; color: #000">
                  <div>{{ listItem.compilationsTitle }}</div>
                  <div style="display: flex;justify-content: space-between;">
                    <div>共{{ listItem.videoNum }}集</div>
                    <div style="color: red">￥{{ listItem.packagePrice }}</div>
                  </div>
                </div>
              </a>
            </el-card>
          </el-col>
        </el-row>
        <div style="padding-top: 20px; margin-left: -10px">
          <el-pagination
            @current-change="currentChange"
            :page-size.sync="pageSize"
            :current-page.sync="pageNum"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { qilinMedia } from "@/http/request";

export default {
  name: "Course",
  data() {
    return {
      pageNum: 1,
      pageSize: 12,
      total: 120,
      currentPath: 0,
      userData: null,
      list: [],

      items: [{ label: "课程推荐", path: "/videoCompilations/list" }],
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.currentPath = this.items[0].path;
    await this.loadData();
  },
  methods: {
    currentChange() {
      this.loadData();
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.currentPath = this.items[parseInt(tab.index)].path;
      this.loadData();
    },
    async loadData() {
      const { pageNum, pageSize } = this;
      const {
        data: { list },
      } = await qilinMedia.post(this.currentPath, {
        // key: "444",
        limit: pageSize,
        order: "asc",
        page: pageNum,
        sidx: "package_price",
      });
      if (list.length < 12) {
        this.total = this.pageNum * this.pageSize;
      }
      this.list = list;
    },
  },
};
</script>
