<template>
  <div class="short-video-player">
            
    <swiper-video :videoList="videoList" :innerWidth="w" :innerHeight="h" @reachEnd="reachEnd">
    </swiper-video>
    <!-- 关闭按钮 -->
    <div class="to-back" @click="back()">
      <i class="van-icon van-icon-cross"></i>
    </div>

    <el-dialog :visible="openPayDialog" :before-close="closePayDialog" width="500px" title="购买年卡会员">
      <Pay />
    </el-dialog>
  </div>
</template>

<script>
import Pay from "@/views/dashboard/Pay";
import { qilinMedia } from "@/http/request";

import SwiperVideo from "@/components/swiper/index.vue";
var qs = require('qs');
import axios from "@/http/https";

export default {
  components: {
    SwiperVideo,Pay
  },
  data() {
    return {
      pageNum: 1,
      userData: {},
      videoList: [],
      w: "100%",
      h: "100%",
      openPayDialog:false
    };
  },
  methods: {
    back(){
      const back = this.$route.query.back;
      console.log(back)
      if(back){
        this.$router.back(-1)
      }else if(this.$route.path.startsWith('/player')){
        window.close()
      }else{
        this.$router.back(-1)
      }
    },
    reachEnd(){
        this.pageNum++
        this._videoList();
    },
    closePayDialog(){
      this.openPayDialog=false
    },   
    async _videoList() {
      var parm = qs.stringify({
        categoryId: JSON.parse(localStorage.getItem("categoryId")),
        pageNum: this.pageNum,
        pageSize: 10,
      });
      //alert(tpid);
      //var tk=JSON.parse(localStorage.getItem('userData')).token;
      const { code, data } =await axios.post(
        "/cecommend/recommendVideo",
        parm,
        {
          Headers: {
            token: sessionStorage.getItem("utoken"),
          },
        }
      );
      this.videoList = this.videoList.concat(data);
      
    },
  },
  async created() {
    var parm = qs.stringify({
        videoId: this.$route.query.id
    })
    const {data:{user}}= await qilinMedia.post('/watchFull/watchFullVideo',parm)
    const videoData = JSON.parse(localStorage.getItem("rowData"));
    const propList=['userImg','isLike','isCollect','videoLikeNum','commentNum']
    const propNameList=['img','isGiveLike','isCollect','giveLike','commentNum']
    propList.forEach((propName,index)=>{
      if(!videoData[propName]){
        videoData[propName]=user[propNameList[index]]
      }
    })
    
    console.log("videoData", videoData);
    this.videoList = [videoData];
    const useDataJson=localStorage.getItem("userData")
    if(useDataJson){
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    this._videoList();
    this.openPayDialog=!this.userData || this.userData.memberStatus != 1
    console.log('openPayDialog',this.openPayDialog)
  },
};
</script>

<style lang="less" scoped>
// 关闭按钮
.to-back {
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
  text-align: center;
  opacity: 0.5;
  i {
    color: #000;
    font-size: 30px;
    font-weight: bold;
    line-height: 64px;
  }
  &:hover {
    opacity: 1;
  }
}
</style>
