<template>
    <div class="short-video-player">
        <swiper-video 
            @reachEnd="reachEnd"
            :videoList="videoList"
            :innerWidth="w"
            :innerHeight="h">
        </swiper-video>
    </div>
</template>

<script>
import SwiperVideo from "@/components/swiper/index.vue"
export default {
    components: {
        SwiperVideo
    },
    data() {
        return {
            pageNum:1,
            videoList: [],
            w: "88%",
            h: "calc(100% - 130px)"
        };
    },
    methods: {
        reachEnd(){
            this.pageNum++
            this._videoList();
        },       
        _videoList() {
            /*
            this.$http.video.hotList().then(({ code, data }) => {
                if (code === 200) {
                    this.videoList = this.videoList.concat(data.list);
                }
            })*/
            //alert(2)
            if(localStorage.getItem('userData')==null)
            {
                this.$alert('<strong><i>对不起，登录系统后才能查看您的关注内容</i> </strong>', '提示', {
                    dangerouslyUseHTMLString: true
                    }); 
                return;
            }
            this.$https.videos.followList({},this.pageNum).then(({code,data }) => {
                //console.log(data[0].videoPlayUrl)
                //if (code === 200) {
                    this.videoList = this.videoList.concat(data);
                //}
            })
        },
    },
    created() {
        this._videoList();
    },
};
</script>

<style lang="less" scoped>

</style>