<template>
  <div
    class="swiper-video-container"
    :style="`width: ${innerWidth}; height: ${innerHeight}`"
  >
    <!-- 轮播区域 -->
    <swiper
      class="my-swipe"
      ref="playerSwiper"
      :options="swiperOptions"
      v-if="videoList.length"
      @slideChange="_slideChange"
      @reachEnd="reachEnd"
      navigation
    >
      <swiper-slide v-for="(item, index) in videoList" :key="index">
        <div
          style="
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -100px;
            font-size: 32px;
            background: #2b2c36;
            width: 50px;
            z-index: 2;
            color: #fff;
            text-align: center;
            padding: 20px 0;
          "
        >
          <i @click="go(-1)" class="el-icon-arrow-up"></i>
          <i @click="go(1)" class="el-icon-arrow-down"></i>
          <div><el-avatar :src="item.userImg"></el-avatar></div>

          <div @click="giveLike(item)">
            <div v-if="item.isLike">
              <img style="width: 32px" src="@/assets/heart-on.png" />
            </div>
            <div v-else>
              <img style="width: 32px" src="@/assets/heart-off.png" />
            </div>
          </div>

          <div style="font-size: 14px; margin-top: -10px; margin-bottom: 10px">
            {{ item.videoLikeNum || 0 }}
          </div>

          <div @click="openComment(item)">
            <div><i class="el-icon-chat-dot-round"></i></div>
            <div style="font-size: 14px; margin-top: -5px; margin-bottom: 10px">
              {{ item.commentNum || 0 }}
            </div>
          </div>

          <div @click="insertVideoCollect(item)">
            <i
              v-if="item.isCollect"
              style="color: #ffb802"
              class="el-icon-star-on"
            ></i>
            <i v-else class="el-icon-star-off"></i>
          </div>
        </div>
        <!-- 右侧信息区域 -->
        <div class="right_menus"></div>
        <!-- 底部文本区域 -->
        <div class="text-container">
          <div class="nickname">
            @{{ item.nickname ? item.nickname : item.username }}
            <span style="font-size: 12px">{{ item.userTitle }}</span>
            <div style="font-size: 12px; margin-top: 5px; margin-bottom: 5px">
              {{ item.labelOne }}
            </div>
          </div>
          <div class="msg">
            {{ item.videoTitle
            }}<span
              v-show="showfull"
              style="float: right; color: red"
              @click="gofullvideo(item)"
              >>>免费试听</span
            >
          </div>
        </div>
        <!-- 播放器 -->
        <div
          class="video-content"
          :id="`player${index}`"
          preload="auto"
          controls
          loop
          width="100%"
          type="video/mp4"
          playsinline="true"
          x5-playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="allow"
          oncontextmenu="return false;"
          controlslist="nodownload noremoteplayback"
          :disablePictureInPicture="true"
        ></div>
      </swiper-slide>
    </swiper>
    <el-drawer
      append-to-body
      :title="drawer.comments.length + '条评论'"
      :visible.sync="drawer.open"
      direction="rtl"
    >
      <div style="margin-bottom: 55px">
        <div
          v-for="commentItem in drawer.comments"
          @click="reply(commentItem)"
          :key="commentItem.commentId"
        >
          <div style="display: flex">
            <div style="margin: 10px">
              <el-avatar :src="commentItem.commentUserImg"></el-avatar>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 15px;
              "
            >
              <div>{{ commentItem.commentUserName }}</div>
              <div>
                {{ commentItem.comment }}
                <span style="font-size: 12px">{{
                  commentItem.formerlyTime
                }}</span>
              </div>
            </div>
          </div>

          <div
            v-for="replyItem in commentItem.replys"
            :key="replyItem.replyId"
            style="margin-left: 50px; display: flex"
          >
            <div style="margin: 10px">
              <el-avatar :src="replyItem.replyUserImg"></el-avatar>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 15px;
              "
            >
              <div>{{ replyItem.replyUserName }}</div>
              <div>
                {{ replyItem.comment }}
                <span style="font-size: 12px">{{ replyItem.replyTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!drawer.isAllComments">
          <el-link type="info" @click="loadComments()">加载更多</el-link>
        </div>
        <div
          style="
            display: flex;
            margin: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          "
        >
          <el-input
            :placeholder="
              drawer.isReply ? '回复@' + drawer.commentUserName : '请输入内容'
            "
            v-model="drawer.comment"
            style="margin-right: 10px"
          >
          </el-input>
          <el-button type="primary" @click="insertComment()" slot="append"
            >评论</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { qilinMedia } from "@/http/request";
var qs = require("qs");

export default {
  props: ["videoList", "innerWidth", "innerHeight"],
  data() {
    return {
      isVip: false,
      swiperOptions: {
        direction: "vertical",
        mousewheel: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showfull: true,
      userData: null,
      drawer: this.newDrawer(),
    };
  },
  computed: {
    swiper() {
      return this.$refs.playerSwiper.$swiper;
    },
  },

  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.isVip = this.userData && this.userData.memberStatus == 1;
    var url = window.location.href;
    //获取当前url
    var dz_url = url.split("?"); //获取#/之前的字符串
    var vid = 0;
    var vname = "";
    if (dz_url.length > 1) {
      vname = dz_url[1].split("=")[0];
      vid = dz_url[1].split("=")[1];
    }

    if (this.$route.path.startsWith("/player")) {
      if (this.isVip) {
        this.showfull = false;
      } else {
        this.showfull = true;
      }
    } else {
      if (vid > 0 && vname == "id") {
        this.showfull = false;
      }
    }

    const videoList = this.videoList;
    console.log("videoList.length", videoList.length);
    setTimeout(() => {
      this.initVideoList();
    }, 0);
  },
  watch: {
    videoList(to, from) {
      this.initVideoList();
    },
    $route(to, from) {
      //alert(this.$route.query.skey)
      //this.getData(this.$route.query.id)
      var id = this.$route.query.id;
      //alert("a:"+id);
      if (id > 0) {
        //alert(id)
        this.showfull = false;
      } else {
        this.showfull = true;
      }

      //this.videoList(k);
    },
  },
  methods: {
    getVideo() {},
    reply(comment) {
      this.drawer.commentId = comment.commentId;
      this.drawer.commentUserId = comment.commentUserId;
      this.drawer.commentUserName = comment.commentUserName;
      this.drawer.isReply = true;
    },
    async insertComment() {
      if (this.drawer.comment) {
        var parm = null;
        if (this.drawer.commentId) {
          parm = qs.stringify({
            videoId: this.drawer.videoId,
            content: this.drawer.comment,
            commentId: this.drawer.commentId,
            replyUserId: this.drawer.commentUserId,
          });
        } else {
          parm = qs.stringify({
            soleId: this.drawer.videoId,
            content: this.drawer.comment,
          });
        }

        const { success, stateDesc } = await qilinMedia.post(
          this.drawer.commentId
            ? "/comment/insertCommentReply"
            : "/comment/insertComment",
          parm
        );
        if (success) {
          this.reloadComment();
        } else {
          alert(stateDesc);
        }
      } else {
        alert("请输入评论内容");
      }
    },
    initVideoList() {
      setTimeout(() => {
        this.videoList.forEach(async (item, index) => {
          if (!item.player) {
            const selecter = `player${index}`;
            let videoData = item;
            if (this.isVip) {
              const {
                data: { video },
              } = await this.$https.videos.fullvoide(item.videoId);
              videoData = video;
            }
            item.player = new Aliplayer(
              {
                speedLevels: [
                  { key: 0.75, text: "0.75" },
                  { key: 1, text: "原速" },
                  { key: 1.25, text: "1.25" },
                  { key: 1.5, text: "1.5" },
                  { key: 2, text: "2" },
                ],
                // controlBarVisibility:'always',
                autoplay: index == 0,
                id: selecter,
                source: !this.showfull
                  ? videoData.videoPlayUrl
                  : videoData.videoEditingPlayUrl, //播放地址，可以是第三方点播地址，或阿里云点播服务中的播放地址。
              },
              function (player) {
                console.log("The player is created.");
              }
            );
            setTimeout(() => {
              var element = document.getElementById(selecter);
              if (element) {
                element.style.height = "";
              }
            }, 0);
          }
        });
      }, 0);
    },
    async giveLike(video) {
      var parm = qs.stringify({
        videoId: video.videoId,
      });
      const {
        success,
        stateDesc,
        data: { likeNum },
      } = await qilinMedia.post("/giveLike/giveLike", parm);
      if (success) {
        video.isLike = !video.isLike;
        video.videoLikeNum = likeNum;
      } else {
        alert(stateDesc);
      }
    },
    newDrawer() {
      return {
        videoId: null,
        open: false,
        comments: [],
        pageNum: 1,
        pageSize: 100,
        comment: "",
        commentId: null,
        commentUserId: null,
        commentUserName: null,
        isReply: false,
        isAllComments: false,
      };
    },
    async openComment(video) {
      this.drawer = this.newDrawer();
      this.drawer.open = true;
      this.drawer.pageNum = 0;
      this.drawer.videoId = video.videoId;
      this.drawer.comments = [];
      this.loadComments();
    },
    async reloadComment() {
      this.drawer.pageNum = 0;
      this.drawer.comments = [];
      this.loadComments();
    },
    async loadComments() {
      this.drawer.pageNum++;
      var parm = qs.stringify({
        videoId: this.drawer.videoId,
        pageNum: this.drawer.pageNum,
        pageSize: this.drawer.pageSize,
      });
      const {
        success,
        stateDesc,
        data: { comments, isAllComments },
      } = await qilinMedia.post("/comment/show", parm);
      if (success) {
        this.drawer.comments = this.drawer.comments.concat(comments);
        this.drawer.isAllComments = isAllComments;
      } else {
        alert(stateDesc);
      }
    },
    async insertVideoCollect(video) {
      var parm = qs.stringify({
        videoId: video.videoId,
      });
      const { success, stateDesc } = await qilinMedia.post(
        video.isCollect
          ? "/collect/cancelVideoCollect"
          : "/collect/insertVideoCollect",
        parm
      );
      if (success) {
        video.isCollect = !video.isCollect;
      } else {
        alert(stateDesc);
      }
    },

    gofullvideo(item) {
      //console.log("初始")
      //console.log(item);

      if (
        localStorage.getItem("userData") == null ||
        localStorage.getItem("userData") == ""
      ) {
        this.$alert("<strong><i>请登录</i> 系统</strong>", "提示", {
          dangerouslyUseHTMLString: true,
        });
      } else {
        //alert(vid);
        this.$https.videos.fullvoide(item.videoId).then(({ data }) => {
          //alert(111)
          //console.log("新：")
          //console.log(data);
          var cansee = data.canSee.type;

          //alert(cansee);
          if (cansee == 0) {
            //alert(0);
            this.$alert(
              "<strong><i>对不起，非会员没有查看完整视频权限</i> 请开通会员</strong>",
              "提示",
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            this.$router.push({
              name: "Player",
              query: { id: item.videoId, back: true },
            });

            localStorage.setItem("rowData", JSON.stringify(item));
          }
        });
      }
    },
    reachEnd() {
      this.$emit("reachEnd");
    },
    go(num) {
      if (num > 0) {
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
    // swiper 切换
    _slideChange() {
      const index = this.swiper.activeIndex;

      this.$emit("slideChange", index);
      this.videoList.forEach((item, s_index) => {
        if (item.player) {
          if (s_index == index) {
            item.player.play();
            item.play = true;
          } else {
            item.player.pause();
            item.play = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-video-container {
  position: fixed;
  // width: 88%;
  // height: calc(100% - 130px);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  .my-swipe {
    position: relative;
    width: 100%;
    // 解决滑动轮播时有白色线条的Bug
    height: 102%;
    .swiper-slide {
      position: relative;
      height: 100%;
      .video-content {
        width: 100%;
        height: 100%;
        background-color: #000;
        // object-fit: cover;
      }
      .right_menus {
        position: absolute;
        width: 50px;
        bottom: 80px;
        right: 20px;
        z-index: 1;
        text-align: center;
        .menuClick {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          .avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .follow {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            font-size: 12px;
            color: #fff;
            background-color: #fe2c55;
            border-radius: 50%;
            line-height: 20px;
          }
        }
        .click-info {
          position: relative;
          margin-bottom: 20px;
          i {
            font-size: 24px;
            color: #fff;
          }
          .text {
            color: #fff;
            font-size: 16px;
            margin-top: 5px;
          }
        }
      }
      .text-container {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        width: 30%;
        left: 0;
        bottom: 80px;
        padding: 10px 30px 10px;
        box-sizing: border-box;
        z-index: 1;
        .nickname {
          width: 100%;
          font-size: 20px;
          color: #fff;
          text-align: left;
        }
        .msg {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          font-size: 16px;
          text-align: left;
          color: #fff;
        }
      }
    }
  }
  // 切换按钮
  .toggle-swiper {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 80px;
    background-color: #323442;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 40px;
    position: static;
    color: #fff;
    margin-top: 0;
    &::after {
      content: "";
    }
    &:hover {
      color: #fe2c55;
    }
    i {
      font-weight: bold;
    }
  }
}

.swiper-video-container .my-swipe .swiper-slide .video-content {
  height: 100vh;
}
.contentBox .swiper-video-container .my-swipe .swiper-slide .video-content {
  height: 100%;
}
</style>
