<template>
  <div>
    <div
      v-for="item in membergoods"
      :key="item.id"
      style="width: 300px; margin: 0 auto"
    >
      <el-card style="text-align: center; line-height: 2em" shadow="always">
        <div style="font-size: 18px">{{ item.name }}</div>
        <div style="color: #ccc">{{ item.explains }}</div>
        <div style="font-size: 18px; font-weight: bold">
          ￥{{ item.activityPrice.toFixed(2) }}
        </div>
        <div style="text-decoration: line-through; color: #ccc">
          {{ item.price.toFixed(2) }}
        </div>
        <el-divider></el-divider>
        <span
          >卡内权益<span style="font-size: 12px; color: #ccc"
            >（{{ item.name }}会员特权）</span
          ></span
        >
        <div>{{ item.equity }}</div>
        <el-divider></el-divider>
        <div v-for="item in payTypeList" :key="item.value">
          <el-radio
            style="width: 200px; margin-bottom: 10px"
            v-model="payType"
            :label="item.value"
            border
            >{{ item.name }}</el-radio
          >
        </div>
        <el-divider></el-divider>
        <div style="margin-bottom: 10px">
          活动价<span style="font-size: 18px; font-weight: bold">
            ￥{{ item.activityPrice.toFixed(2) }}
          </span>
          <el-tag type="warning"
            >高享值用户专享-￥{{
              (item.price - item.activityPrice).toFixed(2)
            }}</el-tag
          >
        </div>
        <div>
          <el-popover
            placement="top-end"
            :visible-arrow="true"
            trigger="hover"
            :content="'已优惠￥' + (item.price - item.activityPrice).toFixed(2)"
          >
            <el-button
              slot="reference"
              style="width: 200px; margin-bottom: 10px"
              type="primary"
              @click="payDoPostMember(item)"
              >立即支付￥<span style="font-size: 18px">{{
                item.activityPrice.toFixed(2)
              }}</span>
            </el-button>
          </el-popover>
        </div>
        <div>
          购买即视为同意<span
            @click="showAgreement = true"
            style="color: blue; cursor: pointer"
            >《百万麒麟用户协议》</span
          >
        </div>
      </el-card>
    </div>
    <el-dialog :visible.sync="showAgreement" :append-to-body="true" top="5vh">
      <agreement />
    </el-dialog>
  </div>
</template>
<script>
import { qilinTool, qilinUser } from "@/http/request";
import agreement from "@/components/public/agreement.vue";

export default {
  components: { agreement },
  name: "Pay",
  data() {
    return {
      showAgreement: false,
      userData: null,
      siteList: [],
      loading: true,
      dialogVisible: false,
      membergoods: [],
      payType: "WXPAY",
      alipayHTML: null,
      payTypeList: [
        {
          name: "微信支付",
          value: "WXPAY",
        },
        {
          name: "支付宝支付",
          value: "ALIPAY",
        },
      ],
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.payMember()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
    async payMember() {
      const { data: membergoods, success: membergoodsIsSuccess } =
        await qilinUser.get("/membergoods/list");
      if (membergoodsIsSuccess) {
        this.membergoods = membergoods;
        this.dialogVisible = true;
      } else {
        alert("获取会员信息失败");
      }
    },
    async payDoPostMember(goods) {
      const { data, success } = await qilinTool.post("/pay/doPostMember", {
        amount: goods.activityPrice.toFixed(2),
        bankCode: this.payType,
        // compilationsId: 0,
        openId: "",
        source: "pc",
        termType: "XCJSAPI",
        traceId: this.guid(),
      });
      if (success) {
        if (this.payType == "WXPAY") {
          const { h5_url } = JSON.parse(data);
          window.location.href = h5_url;
        } else if (this.payType == "ALIPAY") {
          var alipayNode = document.createElement("div");
          document.body.appendChild(alipayNode);
          alipayNode.innerHTML = data;
          document.forms[0].submit();
        }
      } else {
        alert("支付失败");
      }
    },
    goLogin() {
      this.$store.commit("isLogin", true);
    },
  },
};
</script>
