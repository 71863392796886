<template>
  <div class="hd_login">
    <div class="hd_left">
      <el-dropdown v-show="showlogin">
        <a @click="fnList" href="javascript:void(0)" class="el-dropdown-link"
          >注册/登录
        </a>
      </el-dropdown>
      <!-- <el-dropdown v-show="!showlogin">
        <a @click="fnList" href="javascript:void(0)" class="el-dropdown-link"
          >视频收藏
        </a>
      </el-dropdown>
      <el-dropdown v-show="!showlogin">
        <a @click="fnList" href="javascript:void(0)" class="el-dropdown-link"
          >浏览历史
        </a>
      </el-dropdown> -->

      <!-- <button id="login" v-show="showlogin" trigger="click"  class="btn_login" :close-on-click-modal="false" :modal-append-to-body="false">登录</button> -->
      <!-- 课程类目 -->
      <el-dropdown class="first" placement="bottom">
        <span class="el-dropdown-link">课程类目</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in tpArr" :key="index"
            ><a href="javascript:void(0)" @click="navtp(tpArr[index].id)">{{
              tpArr[index].name
            }}</a></el-dropdown-item
          >
          <!--
                    <el-dropdown-item>认证与合作</el-dropdown-item>
                    <el-dropdown-item>广告投放</el-dropdown-item>
                    <el-dropdown-item>关于我们</el-dropdown-item>
                    <el-dropdown-item>抖音电商</el-dropdown-item>
                    <el-dropdown-item>透明度报告</el-dropdown-item>-->
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 关于我们 -->

      <!-- <a
                    href="http://www.baiwanqilin.com/#/aboutUs"
                    target="_blank"
                    >关于我们
                </a> -->
      <el-dropdown>
        <a
          target="_blank"
          href="/admin/#/aboutUs"
          class="el-dropdown-link"
          >关于我们
        </a>
        <el-dropdown-menu slot="dropdown" class="el_end"></el-dropdown-menu>
      </el-dropdown>
      <el-dropdown>
        <a
          target="_blank"
          href="/admin/#/nash"
          class="el-dropdown-link"
          >招贤纳士
        </a>
        <el-dropdown-menu slot="dropdown" class="el_end"></el-dropdown-menu>
      </el-dropdown>

      <el-dropdown>
        <a
          target="_blank"
          href="/admin/#/contactUs"
          class="el-dropdown-link"
          >联系我们
        </a>
        <el-dropdown-menu slot="dropdown" class="el_end"></el-dropdown-menu>
      </el-dropdown>
      <el-dropdown>
        <a
          href="/admin/#/home"
          target="_blank"
          class="el-dropdown-link"
          >玩法介绍
        </a>
        <el-dropdown-menu slot="dropdown" class="el_end"></el-dropdown-menu>
      </el-dropdown>
      <!-- 添加收藏 -->
      <el-dropdown placement="bottom">
        <span class="el-dropdown-link">微信端</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="dsk">
            <img
              class="icon"
              src="@/assets/wxm.jpg"
              alt=""
              style="width: 175px; height: 175px"
            />
            <!--
                        <p>添加百万麒麟到收藏夹</p>
                        <p style="font-size: 12px">
                            下次可以便捷访问百万麒麟
                        </p>-->
            <a
              href="javascript:void(0)"
              style="display: block"
              rel="sidebar"
              class="btn_add"
              >微信扫码观看</a
            >
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 发布与登录 -->
      <el-dropdown v-show="!showlogin">
        <a
          href="/admin/#/uploadVideo"
          target="_blank"
          class="el-dropdown-link"
          >创作者平台
        </a>
        <el-dropdown-menu slot="dropdown" class="el_end">
          <el-dropdown-item>
            <a
              href="/admin/#/uploadVideo"
              target="_blank"
              class="el-dropdown-link"
              >创作者平台
            </a>
          </el-dropdown-item>
          <el-dropdown-item>
            <a href="javascript:void(0)" @click="logOut">安全退出</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="login_box">
        <el-dialog
          :visible.sync="isLogin"
          v-model="isOpen"
          append-to-body
          class="dialogLogin"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
        >
          <Login />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "@/views/Login.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      isOpen: false,
      showlogin: true,
      tpid: "1",
      tpArr: [],
    };
  },
  computed: {
    isLogin () {
      return this.$store.state.isLogin
    }
  },
  created() {
    this.tpList();

    const userData = localStorage.getItem("userData");
    //alert(userData);
    if (userData) {
      //alert(userData)
      this.showlogin = false;
    } else {
      this.showlogin = true;
    }
  },
  methods: {
    tpList() {
      this.$https.videos.tpList().then(({ data }) => {
        this.tpArr = data;
      });
    },
    navtp(ntpid) {
      var url = window.location.href; //获取当前url
      var dz_url = url.split("?");
      if (dz_url.length > 1) {
        this.tpid = dz_url[1].split("=")[1];
      }
      //alert(ntpid+"|"+this.tpid);
      var dzz_url = dz_url[0];
      window.location.href = dzz_url + "?tp=" + ntpid;
      setTimeout(() => {
        this.reload();
      }, 50);
    },
    _addFavorite() {
      var url = window.location;
      var title = document.title;
      var ua = navigator.userAgent.toLowerCase();

      if (document.all) {
        //alert(1)
        window.external.addFavorite(url, title);
      } else if (window.sidebar) {
        //alert(2)
        window.sidebar.addPanel(title, url, "");
      } else {
        alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
      }
    },
    fnList(id) {
      // if (id == 'login') {
      const userData = localStorage.getItem("userData");
      //alert(userData);
      if (userData) {
        //alert(1);
        //this.$router.push({ name: 'video' });
        this.$store.commit("isLogin", false);
      } else {
        //alert(2);
        this.$store.commit("isLogin", true);
      }
      if (userData) {
        this.$router.push({ name: "video" });
      } else {
        this.$store.commit("isLogin", true);
      }
      userData;

      //} else {
      //    this.$router.push({ name: id });
      //}
    },
    logOut() {
      //this.$router.push('/');
      localStorage.removeItem("userData");
      //alert('退出成功');
      //Response.redirect("/");
      window.location.href = "/";
      //alert('')s
    },
  },
  components: { Login },
};
</script>

<style lang="less" scoped>
// Element 样式
.el-dropdown {
  line-height: 40px;
  padding: 0 16px;
}
.first {
  padding-left: 0;
}
.el-dropdown-link {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}
.el-dropdown-link:hover {
  color: rgb(242, 42, 81);
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu {
  border: 1px solid #252632;
  background-color: #252632;
  a {
    font-size: 14px;
  }
}
.el_end {
  border: 0;
}
.el-dropdown-menu__item {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.el-dropdown-menu__item:hover {
  background: none !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
/deep/ .popper__arrow {
  display: none !important;
}
// Add to desktop
.dsk {
  padding: 0 30px 20px 30px;
  p {
    line-height: 24px;
  }
}
.icon {
  display: block;
  width: 55px;
  height: 55px;
  margin: 20px auto;
  margin-bottom: 10px;
}
.btn_add {
  width: 180px;
  height: 38px;
  background-color: #fe2c55;
  border-radius: 4px;
  margin-top: 20px;
  color: #fff;
  font-size: 14px;
}
.btn_add:hover {
  background-color: #f22a51;
}
// Publish video and logins
.hd_left {
  float: right;
  position: relative;
  .box_r {
    position: relative;
    width: 108px;
    height: 40px;
    // background-color: red;
    position: absolute;
    top: 0;
    right: 0;
    .btn_login {
      width: 104px;
      height: 38px;
      line-height: 38px;
      background-color: #fe2c55;
      border-radius: 4px;
      color: #fff;
      font-size: 15px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    .btn_login:hover {
      background-color: #f22a51;
    }
    .up_video {
      width: 108px;
      height: 40px;
      background-color: #161722;
      border-radius: 6px;
      border: 2px solid #fff;
      color: #fff;
      font-size: 15px;
    }
  }
}
// 公共样式
.btn_add,
.btn_login,
.up_video {
  font-family: "PingFang SC", "Lantinghei SC", "Microsoft YaHei",
    "Helvetica Neue", "Open Sans", Arial, "Hiragino Sans GB", sans-serif;
  cursor: pointer;
}
::v-deep .login_box {
  .dialogLogin {
    z-index: 9999 !important;
    //width: 460px;
    .el-dialog {
      max-width: 460px;
      .el-dialog__header,
      .el-dialog__body {
        padding: 0;
      }
      .el-dialog__headerbtn {
        top: 32px;
        right: 40px;
        .el-icon-close {
          font-size: 30px;
        }
      }
    }
  }
  .v-modal {
    z-index: 1998 !important;
  }
}
</style>
